@import './colors';
@import './variables';

@mixin size($size) {
  width: $size;
  height: $size;
}

@mixin dimensions($width, $height) {
  width: $width;
  height: $height;
}

@mixin absolute($top: 0, $left: 0) {
  position: absolute;
  top: $top;
  left: $left;
}

@mixin absolute-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin object-fit($fit: cover, $position: center) {
  object-fit: $fit;
  object-position: $position;
}

@mixin border(
  $position: null,
  $color: $border-color,
  $width: 1px,
  $type: solid
) {
  @if ($position == null) {
    border: $width $type $color;
  } @else {
    border-#{$position}: $width $type $color;
  }
}

@mixin flex($align: flex-start, $justify: flex-start, $direction: row) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-center-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@mixin flex-space-between {
  display: flex;
  justify-content: space-between;
}

@mixin flex-fixed {
  flex-shrink: 0;
  flex-grow: 0;
}

@mixin flex($alignItems: flex-start, $justifyContent: flex-start, $direction: row) {
  display: flex;
  align-items: $alignItems;
  justify-content: $justifyContent;
  flex-direction: $direction;
}

@mixin font-barlow(
  $size: 14px,
  $lineHeight: 18px,
  $fontWeight: $font-regular,
  $letterSpacing: 0px
) {
  font-family: $font-family-barlow;
  font-size: $size;
  line-height: $lineHeight;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin font-lato(
  $size: 14px,
  $lineHeight: 18px,
  $fontWeight: $font-regular,
  $letterSpacing: 0px
) {
  font-family: $font-family-lato;
  font-size: $size;
  line-height: $lineHeight;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin font-nanum-pen(
  $size: 14px,
  $lineHeight: 18px,
  $fontWeight: $font-regular,
  $letterSpacing: 0px
) {
  font-family: $font-family-nanum-pen;
  font-size: $size;
  line-height: $lineHeight;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

@mixin beyond-desktop {
  @media screen and (min-width: 1920px) {
    @content;
  }
}

@mixin more-than-desktop {
  @media screen and (min-width: 1600px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1225px) {
    @content;
  }
}

@mixin tablet-landscape {
  @media screen and (max-width: 1224px) {
    @content;
  }
}

@mixin tablet-portrait {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin mobile-small {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin imx-tablet-landscape {
  @media screen and (max-width: 1365px) {
    @content;
  }
}

@mixin imx-tablet-portrait {
  @media screen and (max-width: 1023px) {
    @content;
  }
}


@mixin imx-mobile {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin imx-mobile-414 {
  @media screen and (max-width: 414px) {
    @content;
  }
}

@mixin imx-mobile-375 {
  @media screen and (max-width: 375px) {
    @content;
  }
}

@mixin imx-mobile-320 {
  @media screen and (max-width: 375px) {
    @content;
  }
}

@mixin text-highlight($gradient: linear-gradient(93.78deg, #45DEFF -21.71%, #1A9FFF 85.87%)) {
  @supports (-webkit-background-clip: text) {
    background: $gradient,  #1A9FFF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @supports not (-webkit-background-clip: text) {
    color: #1A9FFF;
  }
}
